import React from 'react';

const ProfileContent = () => {
  return (
    <div>
      <h1>Here is your profile information.</h1>
      {/* Aquí puedes añadir más contenido y lógica específica para la página de perfil */}
    </div>
  );
};

export default ProfileContent;